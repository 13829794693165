import type { QBus } from '@otto-ec/global-resources/event-q-bus';
import { logTrackingWithPayload, serviceLogger } from '../Logger';

const log = serviceLogger.scope('tracking-service');

/*                       */
declare let o_global: { eventQBus };

export class TrackingService {
	private readonly o_util;

	private readonly eventBus;

	private readonly debugEventBus = {
		/*                       */
		emit: (channel, payload, mergeId) => {
			log.warn(`Sending to ${channel} via debug eventBus`, payload);
		}
	};

	/*                       */
	constructor(o_global, o_util, eventQBus?: QBus) {
		this.o_util = o_util;
		this.eventBus = eventQBus ||
			((o_global !== null && typeof o_global !== 'undefined' &&
				o_global.eventQBus !== null && typeof o_global.eventQBus !==
				'undefined') ? o_global.eventQBus : this.debugEventBus);
	}

	sendMerge(trackingPayload: { [key: string]: string; }) {
		if (trackingPayload === null || trackingPayload === undefined) {
			return;
		}
		this.eventBus.emit('tracking.bct.addToPageImpression', trackingPayload);
		logTrackingWithPayload(log, 'tracking.bct.addToPageImpression', trackingPayload);
	}

	sendEvent(trackingPayload: { [key: string]: string; }) {
		if (trackingPayload === null || trackingPayload === undefined) {
			return;
		}
		this.eventBus.emit('tracking.bct.submitEvent', trackingPayload);
		logTrackingWithPayload(log, 'tracking.bct.submitEvent', trackingPayload);
	}

	sendTrackOnNext(trackingPayload: { [key: string]: string; }) {
		if (trackingPayload === null || trackingPayload === undefined) {
			return;
		}
		this.eventBus.emit('tracking.bct.trackOnNextPageImpression',
			trackingPayload);
		logTrackingWithPayload(log, 'tracking.bct.trackOnNextPageImpression',
			trackingPayload);
	}
}
