import { TrackingService } from '../tracking/TrackingService';
import { SheetCloseEvent, TrackingPayload } from '../tracking/TrackingPayload';
import { TeaserSize } from '../tracking/TeaserSize';
import { countdownToMidnight } from '../util/countdownToMidnight';
import { UpTeaserType } from '../util/UpTeaserType';
import { o_up } from '../util/namespace';

export class TrackingUpTeaserService {
	constructor(
		private readonly trackingService: TrackingService
	) {
	}

	init() {
		const teaser = document.querySelector('.up-teaser-tracking');

		if (teaser) {

			this.trackTeaserView(teaser);
			if (!this.isSmallTeaser(teaser)) {
				this.trackTeaserSeen(teaser);
			}
			this.trackTeaserClicked(teaser);

			this.trackSignupSheetClosed(teaser);
			this.updateHeadline(teaser);
		}
	}

	private isSmallTeaser(teaser: Element) {
		return teaser.getAttribute('data-tracking-teaser-size') == 'MediaObject25';
	}

	private updateHeadline(teaser: Element) {
		const countdown = teaser.getAttribute('data-countdown');
		if (countdown == null || countdown === '0') {
			return;
		}

		const endTime = parseInt(countdown);
		const headlineElement = teaser.querySelector('.up_teaser_headline');

		if (isNaN(endTime) || !headlineElement) {
			return;
		}

		const headlinePrefix = headlineElement.textContent;
		const countdownCall = () => countdownToMidnight(endTime,
			`${headlinePrefix} in `, headlineElement,
			'Dein UP Plus ist abgelaufen');
		countdownCall();
		headlineElement['interval'] = window.setInterval(() => {
			countdownCall();
		}, 1000);
	}

	private trackTeaserView(teaser: Element) {
		const trackingPayload = TrackingPayload.onTeaserView(
			<UpTeaserType>teaser.getAttribute('data-tracking-teaser-type'),
			<string>teaser.getAttribute('data-tracking-scarcity'),
			<TeaserSize>teaser.getAttribute('data-tracking-teaser-size')
		);

		this.trackingService.sendMerge(trackingPayload);
	}

	private trackTeaserSeen(teaser: Element) {
		if (window.IntersectionObserver) {
			const observer = new IntersectionObserver((observerEntries, observer) => {
				const observerEntry = observerEntries[0];
				if (!observerEntry.isIntersecting) {
					return;
				}

				observer.unobserve(teaser);
				const trackingPayload = TrackingPayload.onTeaserSeen(
					<UpTeaserType>teaser!.getAttribute('data-tracking-teaser-type'),
					<string>teaser.getAttribute('data-tracking-scarcity'),
					<TeaserSize>teaser!.getAttribute('data-tracking-teaser-size')
				);

				this.trackingService.sendEvent(trackingPayload);
			}, { threshold: 0.75 });

			observer.observe(teaser);
		}
	}

	private trackTeaserClicked(teaser: Element) {
		teaser.addEventListener('click', () => {
			const trackingPayload = TrackingPayload.onTeaserOpened(
				<UpTeaserType>teaser!.getAttribute('data-tracking-teaser-type'),
				<string>teaser.getAttribute('data-tracking-scarcity'),
				<TeaserSize>teaser!.getAttribute('data-tracking-teaser-size')
			);

			this.trackingService.sendEvent(trackingPayload);
		});
	}

	private trackSignupSheetClosed(teaser: Element) {
		const teaserType = teaser.getAttribute('data-tracking-teaser-type');
		window.o_global.eventQBus.on('assets.sheet.closed', (event) => {
			this.trackingService.sendEvent(
				{
					up_Promo: event.closeType,
					up_TeaserType: teaserType
				}
			);
		});
	}

	private oldTrackSignupSheetClosed(teaser: Element) {
		const teaserType = teaser.getAttribute('data-tracking-teaser-type');
		o_up.closeHandlerTeaser = (closeEvent: SheetCloseEvent) => {
			this.trackingService.sendEvent(
				{
					up_Promo: closeEvent.closeType,
					up_TeaserType: teaserType
				}
			);
		};
	}
}
