import { type Debug, logger } from '@otto-ec/global-resources/debug';

/**
 *
 */
export const serviceLogger = logger("up.teaser");

/**
 *
 *
 *
 *
 *
 *
 */
export function logTrackingWithPayload(log: Debug, topic: string, trackingPayload?: { [key: string]: string; }) {
	log.debug(`Tracking event sent to topic: ${topic}`);
	trackingPayload && log.debug(trackingPayload);
}
