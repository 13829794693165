import { TeaserSize } from './TeaserSize';
import { UpTeaserType } from '../util/UpTeaserType';

export type CloseType =
	'closedWithX'
	| 'closedWithCurtainClick'
	| 'closedWithDrag'
	| 'closedProgrammatically';

export interface SheetCloseEvent {
	closeType: CloseType;
}

export class TrackingPayload {
	constructor() {
	}

	static onTeaserView(
		teaserType: UpTeaserType, scarcity?: Scarcity, teaserSize?: TeaserSize) {
		return this.buildTrackingPayloadFor(teaserType, Action.VIEW, teaserSize,
			scarcity);
	}

	static onTeaserSeen(
		teaserType: UpTeaserType, scarcity?: Scarcity, teaserSize?: TeaserSize) {
		return this.buildTrackingPayloadFor(teaserType, Action.SEEN, teaserSize,
			scarcity);
	}

	static onTeaserOpened(
		teaserType: UpTeaserType, scarcity?: Scarcity, teaserSize?: TeaserSize) {
		return this.buildTrackingPayloadFor(teaserType, Action.OPEN, teaserSize,
			scarcity);
	}

	static getScarcityStatus(scarcity: string) {
		if (scarcity === '-1') return Scarcity.EXPIRED;
		if (scarcity === '0') return Scarcity.NO_SCARCITY;
		if (scarcity === '1') return Scarcity.TODAY;
		if (scarcity === '2') return Scarcity.TOMORROW;
		if (scarcity === '3') return Scarcity.TWO_TO_FOURTEEN_DAYS;

		return scarcity as Scarcity;
	}

	private static buildTrackingPayloadFor(
		teaserType: UpTeaserType, upPromo: Action, teaserSize?: TeaserSize,
		scarcity?: Scarcity) {
		const payload: TrackingPayloadData = {
			up_Promo: upPromo,
			up_TeaserType: teaserType
		};

		if (teaserSize) {
			payload.up_TeaserSize = teaserSize;
		}

		if (scarcity) {
			payload.up_Scarcity = this.getScarcityStatus(scarcity);
		}

		return payload;
	}
}

export enum Action {
	VIEW = 'view',
	SEEN = 'seen',
	OPEN = 'open',
	SIGN_UP = 'SignUpLink'
}

export enum Scarcity {
	EXPIRED = 'Expired',
	NO_SCARCITY = 'NoScarcity',
	TODAY = 'Today',
	TOMORROW = 'Tomorrow',
	TWO_TO_FOURTEEN_DAYS = '2to14Days'
}

export interface TrackingPayloadData {
	up_Promo: Action;
	up_TeaserType: UpTeaserType;
	up_Scarcity?: Scarcity;
	up_TeaserSize?: TeaserSize;
	'wk.up_SignUpOrigin': string;

}
